import { useState } from "react";
import { useParams } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import { usePalette, usePalettesApi } from '../../hooks/usePalette';
import { SwatchEdit } from "./SwatchEdit";
import "./SwatchesView.scss";
import { toast } from "react-toastify";

export const SwatchesView = (props) => {

    // router params
    const routerParams = useParams();
    let project_id = routerParams.project_id;
    let palette_id = props.palette_id;

    const api = usePalettesApi();
    const [palette, paletteLoading, paletteError] = usePalette(palette_id);

    const list = palette?.swatches?.map((s, idx) => ({
        ...s,
        tempName: `[${idx}] ${s.name || ""}`
    }));

    const [hideHover, setHideHover] = useState(false);
    let saveList = null;

    const getPaletteString = () => {
        return JSON.stringify(palette, null, 4);
    }

    const copyPalette = async () => {
        const str = getPaletteString();
        await navigator.clipboard.writeText(str);
        toast("copied");

    }

    const content =
        paletteError ? <div className='list-content error'>Error loading palette</div> :
        paletteLoading ? <div className='list-content unknown'>Loading...</div> :
        !list ? null : <ReactSortable
            className='list-content swatches'
            animation={200}
            delayOnTouchStart={true}
            delay={2}
            list={list}
            setList={(list) => {
                saveList = list; // save this ordering so we can updatePalette onEnd
            }}
            onStart={() => {
                setHideHover(true)
            }}
            onEnd={async (ev) => {
                
                const newSwatches = saveList.map(i => ({
                    name: i.name,
                    fg: i.fg,
                    bg: i.bg
                }))
                const newPal = {
                    ...palette,
                    swatches: newSwatches
                }
                await api.updateItem(palette_id, newPal);
                setHideHover(false)
            }}
        >
            {list?.map((item, itemIdx) => {
                //const name = item.name;
                const key = item.tempName; // `[${itemIdx}] ${name}`;
                const swatch = <SwatchEdit
                    key={key}
                    hideHover={hideHover}
                    bg={item.bg} fg={item.fg} name={item.name}
                    
                    onDelete={async () => {
                        // delete item
                        const newList = list.filter(i => i.tempName !== item.tempName);
                        const newSwatches = newList.map(i => ({
                            name: i.name,
                            fg: i.fg,
                            bg: i.bg
                        }))
                        const newPal = {
                            ...palette,
                            swatches: newSwatches
                        }
                        await api.updateItem(palette_id, newPal);
                    }}

                    onSave={async (newSwatch) => {
                        const newList = list.map(i => i.tempName !== item.tempName ? i : newSwatch);
                        const newSwatches = newList.map(i => ({
                            name: i.name,
                            fg: i.fg,
                            bg: i.bg
                        }));
                        const newPal = {
                            ...palette,
                            swatches: newSwatches
                        }
                        await api.updateItem(palette_id, newPal);
                    }}
                    
                />;

                return swatch;

            })}
        </ReactSortable>

    return <div className='swatches-viewbox'>

        <div className='top-header'>
            <span className='left-section'>
            </span>
            <span className='right-section'>
            </span>
        </div>

        <div className='actions-header'>
            <span className='btn action' onClick={async () => {

                const newSwatch = {
                    bg: "#202020",
                    fg: "#efefef"
                };
                const newSwatches = [...(palette.swatches || []), newSwatch];
                const newPal = {
                    ...palette,
                    swatches: newSwatches
                }
                await api.updateItem(palette_id, newPal);
            }}>
                <i className='fal fa-plus' />&nbsp; New Swatch
            </span>
            <span className='btn action margin-left' onClick={copyPalette}>
                <i className="fad fa-copy" /> &nbsp; Copy
            </span>
        </div>

        {content}

    </div>;


}
